import React, { useContext } from "react"
import styled from "styled-components"
import { colors, mq } from "utils/styles"

import Curator from "views/curators/Curator"
import BackButton from "components/misc/BackButton"
import Menu from "../components/Menu"
import { useSiteContent } from "../hooks/useSiteContent"
import SEO from "../components/seo/SEO"
import { graphql, Link } from "gatsby"
import useWindowSize from "../hooks/useWindowSize"
import EventsPassCulture from "../views/curators/EventsPassCulture"
import { StaticImage } from "gatsby-plugin-image"
import {
  curatorsStrings,
  makeCurrentLocationObject,
} from "../utils/localization"
import I18nContext from "../utils/i18nContext"

const Wrapper = styled.div`
  position: relative;
  z-index: 120;
  min-height: ${props =>
    props.windowHeight ? props.windowHeight + "px" : "100dvh"};
  background-color: ${colors.background};
`
const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`
const PassCultureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PassCultureTitle = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
  font-size: 20px;

  a {
    color: #fff;
    text-decoration: none;
  }
`
const Title = styled.h1`
  margin: 0;
`
const SmallTitle = styled.h3`
  margin: 0;
  text-align: center;
  color: #fff;
`
const Content = styled.div`
  padding: 1em 1em;

  ${mq.large} {
    width: 800px;
    margin: 0 auto;
  }
`
const LogosWrapper = styled.div`
  padding: 1.5rem 3rem;
  border: 1px solid rgba(255, 255, 255, 0.25);
`
const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0 0;
`
const Logo = styled.div`
  position: relative;
`

export default function Curators({ data, pageContext }) {
  const windowSize = useWindowSize()
  const { curators } = useSiteContent()

  const { translate } = useContext(I18nContext)
  const locale = pageContext.locale
  const translations = {
    fr: {
      slug: curatorsStrings.slug.fr,
    },
    en: {
      slug: curatorsStrings.slug.en,
    },
  }
  const currentLocation = makeCurrentLocationObject("gatsbyPage", translations)
  const eventsEligiblePassCulture = data.eventsPassCulture.nodes
  const eventIds = [
    ...data.events.nodes.map(event => event.id),
    ...data.eventsPasFinis.nodes.map(event => event.id),
  ]
  const curateursAvecEvents = curators.filter(curator => {
    if (!curator.acf.evenements?.length > 0) return false

    return curator.acf.evenements
      .map(event => eventIds.includes(event.id))
      .includes(true)
  })

  const events = [...data.events.nodes, ...data.eventsPasFinis.nodes]

  return (
    <>
      <SEO title={"Liste des curateurs"} slug={"curators"} locale={locale} />
      <Wrapper windowHeight={windowSize.height}>
        <Header>
          <Link to="/">
            <BackButton />
          </Link>
          <Title>{translate(`Liste des curateurs`)}</Title>
          <Menu locale={locale} currentLocation={currentLocation} />
        </Header>
        <Content>
          {curateursAvecEvents.map(curator => (
            <Curator
              key={curator.id}
              curator={curator}
              events={events}
              locale={locale}
            />
          ))}
        </Content>
        {eventsEligiblePassCulture.length > 0 && (
          <Content>
            <PassCultureWrapper>
              <StaticImage
                src={"../img/logo_pass_culture.png"}
                width={300}
                placeholder={"blurred"}
                alt={"pass Culture"}
              />
            </PassCultureWrapper>
            <PassCultureTitle>
              <a
                href="https://passculture.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate(
                  `Liste des événements à retrouver sur le pass Culture`
                )}
              </a>
            </PassCultureTitle>
            {eventsEligiblePassCulture && (
              <EventsPassCulture
                events={eventsEligiblePassCulture}
                locale={locale}
              />
            )}
          </Content>
        )}
        <Content>
          <LogosWrapper>
            <SmallTitle>Ils ont proposé une sélection</SmallTitle>

            <Logos>
              {curators.map((curator, i) => (
                <Logo key={i}>
                  {curator.acf.logo ? (
                    <img
                      src={curator.acf.logo.sourceUrl}
                      style={{ height: "50px" }}
                      alt={curator.title}
                    />
                  ) : (
                    ""
                  )}
                </Logo>
              ))}
            </Logos>
          </LogosWrapper>
        </Content>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query ($today: Date!) {
    eventsPassCulture: allWpEvent(
      filter: {
        eventsAcfFields: {
          eligibleAuPassCulture: { eq: true }
          horaires: { debut: { gte: $today } }
        }
      }
      sort: { fields: eventsAcfFields___horaires___debut }
    ) {
      nodes {
        title
        id: databaseId
        locale {
          id
        }
        translated {
          type: contentTypeName
          databaseId
          slug
          locale {
            id
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf: eventsAcfFields {
          description
          imagePrincipale {
            node {
              sourceUrl
            }
          }
          horaires {
            debut
            fin
          }
          misEnAvant
          lieu {
            nodes {
              id: databaseId
              slug
              type: contentTypeName
            }
          }
          billeterie
          eligibleAuPassCulture
          tarif
        }
        genres {
          nodes {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
          }
        }
        seo {
          canonicalUrl
          description
          openGraphDescription
          openGraphTitle
          openGraphType
          title
          twitterDescription
          twitterTitle
          socialImage {
            sourceUrl
          }
        }
      }
    }
    events: allWpEvent(
      filter: { eventsAcfFields: { horaires: { debut: { gte: $today } } } }
      sort: { fields: eventsAcfFields___horaires___debut }
    ) {
      nodes {
        title
        id: databaseId
        locale {
          id
        }
        translated {
          type: contentTypeName
          databaseId
          slug
          locale {
            id
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf: eventsAcfFields {
          description
          imagePrincipale {
            node {
              sourceUrl
            }
          }
          horaires {
            debut
            fin
          }
          misEnAvant
          lieu {
            nodes {
              id: databaseId
              slug
              type: contentTypeName
            }
          }
          billeterie
          eligibleAuPassCulture
          tarif
        }
        genres {
          nodes {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
          }
        }
        seo {
          canonicalUrl
          description
          openGraphDescription
          openGraphTitle
          openGraphType
          title
          twitterDescription
          twitterTitle
          socialImage {
            sourceUrl
          }
        }
      }
    }
    eventsPasFinis: allWpEvent(
      sort: { fields: eventsAcfFields___horaires___debut }
      filter: {
        eventsAcfFields: {
          horaires: { debut: { lte: $today }, fin: { gte: $today } }
        }
      }
    ) {
      nodes {
        title
        id: databaseId
        locale {
          id
        }
        translated {
          type: contentTypeName
          databaseId
          slug
          locale {
            id
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf: eventsAcfFields {
          description
          imagePrincipale {
            node {
              sourceUrl
            }
          }
          horaires {
            debut
            fin
          }
          misEnAvant
          lieu {
            nodes {
              id: databaseId
              slug
              type: contentTypeName
            }
          }
          billeterie
          eligibleAuPassCulture
          tarif
        }
        genres {
          nodes {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
          }
        }
        seo {
          canonicalUrl
          description
          openGraphDescription
          openGraphTitle
          openGraphType
          title
          twitterDescription
          twitterTitle
          socialImage {
            sourceUrl
          }
        }
      }
    }
  }
`
