import React, { useContext, useState } from "react"
import styled from "styled-components"
import { colors } from "utils/styles"
import Events from "components/Events"
import I18nContext from "../../utils/i18nContext"

const Wrapper = styled.div`
  margin-bottom: 2em;
`
const SelectionButton = styled.div`
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: ${colors.main};
  }
`
const Text = styled.span`
  position: relative;
  display: block;
  width: 14em;
  margin: 0 3em 1em 0;
  padding: 0.3em 1em 0.3em;
  color: ${props => (props.open ? colors.background : colors.main)};
  text-transform: uppercase;
  background-color: ${props => (props.open ? colors.main : colors.background)};
  border: 1px solid ${colors.main};
  border-radius: 2em;
  transition: all 300ms ease-out;
`
const Icon = styled.span`
  display: inline-block;
  width: 0.6em;
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.open ? colors.background : colors.main)};
  transition: all 300ms ease-out;
`
export default function EventsPassCulture(props) {
  const [open, setOpen] = useState(false)
  const { translate } = useContext(I18nContext)

  return (
    <Wrapper>
      <SelectionButton onClick={() => setOpen(!open)}>
        <Text open={open}>
          <Icon open={open}>{open ? "-" : "+"}</Icon>{" "}
          {translate(open ? "Cacher la selection" : "Voir la selection")}
        </Text>
      </SelectionButton>
      {open && (
        <Events
          events={props.events}
          noTitle
          hidePassCulture={true}
          locale={props.locale}
          curatorScreen={true}
        />
      )}
    </Wrapper>
  )
}
