import React, { useContext, useState } from "react"
import styled from "styled-components"

import { colors } from "utils/styles"
import Events from "components/Events"
import Social from "components/venue/Social"
import AddFavoriButton from "../../components/user/profile/Favoris/AddFavoriButton"
import I18nContext from "../../utils/i18nContext"
import checkIfIsFavori from "../../utils/checkIfIsFavori"
import UserContext from "../../utils/UserContext"

const Wrapper = styled.div`
  margin-bottom: 2em;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
const Title = styled.h2``
const Description = styled.div`
  margin-bottom: 1rem;
`
const SelectionButton = styled.div`
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: ${colors.main};
  }
`
const Text = styled.span`
  position: relative;
  display: block;
  width: 14em;
  margin: 0 3em 1em 0;
  padding: 0.3em 1em 0.3em;
  color: ${props => (props.open ? colors.background : colors.main)};
  text-transform: uppercase;
  background-color: ${props => (props.open ? colors.main : colors.background)};
  border: 1px solid ${colors.main};
  border-radius: 2em;
  transition: all 300ms ease-out;
`
const Icon = styled.span`
  display: inline-block;
  width: 0.6em;
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.open ? colors.background : colors.main)};
  transition: all 300ms ease-out;
`
const TitleFavoriWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 1rem;
`

export default function Curator(props) {
  const [open, setOpen] = useState(false)
  const { translate } = useContext(I18nContext)
  const { profile } = useContext(UserContext)
  const isFavori = checkIfIsFavori(props.curator.id, profile?.preferences)

  return (
    props.curator && (
      <Wrapper>
        <Header>
          <TitleFavoriWrapper>
            <AddFavoriButton
              toAdd={props.curator}
              asStar
              textAfter={translate("Curateur favori")}
              textBefore={translate("Ajouter aux favoris")}
              added={isFavori}
            />
            {props.curator.acf.logo ? (
              <img
                src={props.curator.acf.logo.sourceUrl}
                style={{ height: "50px" }}
                alt={props.curator.title}
              />
            ) : (
              <Title
                dangerouslySetInnerHTML={{
                  __html: props.curator.title,
                }}
              />
            )}
          </TitleFavoriWrapper>
          <Social
            links={props.curator.acf.reseauxSociaux[0]}
            website={props.curator.acf.siteWeb}
          />
        </Header>
        <Description
          dangerouslySetInnerHTML={{
            __html: props.curator.acf.description,
          }}
        />
        <SelectionButton onClick={() => setOpen(!open)}>
          <Text open={open}>
            <Icon open={open}>{open ? "-" : "+"}</Icon>{" "}
            {translate(open ? "Cacher la selection" : "Voir la selection")}
          </Text>
        </SelectionButton>
        {open && (
          <Events
            locale={props.locale}
            events={props.events}
            curator={props.curator}
            noTitle
            curatorScreen={true}
          />
        )}
      </Wrapper>
    )
  )
}
